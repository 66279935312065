#chat {
  background-color: slateblue;
  padding: 10px;
  box-shadow: 0 0 15px black;
  width: 100%;
  min-width: 250px;
  max-width: 400px;
}

.chat-body {
  margin-bottom: 5px;
  overflow: scroll;
  background-color: white;
  box-shadow: 0 0 7px black;
  height: 35vh;
  max-height: 400px;
}

.chat-message {
  display: flex;
  flex-direction: column;
}

.blue {
  background-color: rgb(109, 140, 211);
}

.green {
  background-color: rgb(74, 146, 74);
}

.chat-message > p {
  margin: 0;
}