* {
  box-sizing: border-box;
}

.square {
  width: 90px;
  height: 90px;
}

.board-row {
  display: flex;
}