#drum-machine {
  background: linear-gradient(-45deg, #508391, #282c34, #508391);
  border: 10px solid black;
  border-radius: 2%;
  display: flex;
  flex-direction: row;
}

#display {
  height: 40vh;
  width: 20vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#screen {
  background-color: lightgrey;
  width: 10vw;
  height: 5vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

#pads {
  max-height: 40vh;
  padding: 0;
  width: 40vw;
  height: 40vh;
  display: grid;
  grid-template-columns: 33% 33% 33%;
  justify-items: center;
  align-items: center;
}

.drum-pad {
  width: 6vw;
  height: 6vh;
  text-align: center;
  border-radius: 3%;
  background-color: rgb(141, 177, 209);
  box-shadow: 0 0 25px;
}

.slider {
  max-width: 20vw
}